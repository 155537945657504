import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/Alarm_FTP_Upload/NavButtons';
import ForumBox from 'components/Motion_Detection/Alarm_FTP_Upload/ForumBox';
import ConfigTable from 'components/Motion_Detection/Alarm_FTP_Upload/720p_Series/ConfigTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm FTP Upload - 720p Series Cameras",
  "path": "/Motion_Detection/Alarm_FTP_Upload/720p_Series/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Set the FTP upload during alarm and photo series",
  "image": "./MD_SearchThumb_FTPSettingsAlarmUpload_720p.png",
  "social": "/images/Search/MD_SearchThumb_FTPSettingsAlarmUpload_720p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-FTP-Upload_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm FTP Upload - 720p Series Cameras' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Set the FTP upload during alarm and photo series' image='/images/Search/MD_SearchThumb_FTPSettingsAlarmUpload_1080p.png' twitter='/images/Search/MD_SearchThumb_FTPSettingsAlarmUpload_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Alarm_FTP_Upload/720p_Serie/' locationFR='/fr/Motion_Detection/Alarm_FTP_Upload/720p_Series/' crumbLabel="FTP Upload" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "alarm-ftp-upload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-ftp-upload",
        "aria-label": "alarm ftp upload permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm FTP Upload`}</h2>
    <h3 {...{
      "id": "720p-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#720p-cameras",
        "aria-label": "720p cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/655f6e3ef9d646dcc592fc22349c4ab2/29d31/FTP_Einstellungen_HD_1_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABhesmFlgP/8QAGBABAQEBAQAAAAAAAAAAAAAAAQIDAAT/2gAIAQEAAQUC9E0BsxRRfVBQ4ZPGcz3/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAADAQEBAAAAAAAAAAAAAAAAARECIRL/2gAIAQEABj8CubkjXpFR3p3JEj//xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhMUFhgZH/2gAIAQEAAT8hD+QVO57gFx44B4MRBIPZMwie4Opif//aAAwDAQACAAMAAAAQ3D//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAHRABAAMAAwADAAAAAAAAAAAAAQARITFBcVHB0f/aAAgBAQABPxBI7BW9Hk/MUg5tqesvJWV9DBMPuDbgNK79mqp6/ZRRddCz/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/655f6e3ef9d646dcc592fc22349c4ab2/e4706/FTP_Einstellungen_HD_1_en.avif 230w", "/en/static/655f6e3ef9d646dcc592fc22349c4ab2/d1af7/FTP_Einstellungen_HD_1_en.avif 460w", "/en/static/655f6e3ef9d646dcc592fc22349c4ab2/70e80/FTP_Einstellungen_HD_1_en.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/655f6e3ef9d646dcc592fc22349c4ab2/a0b58/FTP_Einstellungen_HD_1_en.webp 230w", "/en/static/655f6e3ef9d646dcc592fc22349c4ab2/bc10c/FTP_Einstellungen_HD_1_en.webp 460w", "/en/static/655f6e3ef9d646dcc592fc22349c4ab2/426ac/FTP_Einstellungen_HD_1_en.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/655f6e3ef9d646dcc592fc22349c4ab2/e83b4/FTP_Einstellungen_HD_1_en.jpg 230w", "/en/static/655f6e3ef9d646dcc592fc22349c4ab2/e41a8/FTP_Einstellungen_HD_1_en.jpg 460w", "/en/static/655f6e3ef9d646dcc592fc22349c4ab2/29d31/FTP_Einstellungen_HD_1_en.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/655f6e3ef9d646dcc592fc22349c4ab2/29d31/FTP_Einstellungen_HD_1_en.jpg",
              "alt": "Alarm FTP Upload Setup for the 720p Camera Series",
              "title": "Alarm FTP Upload Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`Open the web interface of your HD IP cam and click Alarm - FTP.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5c6593e3aaf6cf15699512bf8cf74195/20e5d/FTP_Einstellungen_HD_2_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "123.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAgADBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAH2UGCcZaYg6ox//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECIRAxQRH/2gAIAQEAAQUC6OMWdxfqdD2iOv/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABgQAAIDAAAAAAAAAAAAAAAAAAEgECEx/9oACAEBAAY/AosApjf/xAAdEAABBAIDAAAAAAAAAAAAAAABABARITFhQXGB/9oACAEBAAE/IQLMVk5sIGHEgWfqktWpbIuXbX//2gAMAwEAAgADAAAAEHPDMP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB8QAAMAAQMFAAAAAAAAAAAAAAABEXEhMUGhscHw8f/aAAgBAQABPxB1K5tKQmiFKhuCWpxXH094ETNFulReCQ4fJBR8pnXHfG7k/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5c6593e3aaf6cf15699512bf8cf74195/e4706/FTP_Einstellungen_HD_2_en.avif 230w", "/en/static/5c6593e3aaf6cf15699512bf8cf74195/e46ac/FTP_Einstellungen_HD_2_en.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5c6593e3aaf6cf15699512bf8cf74195/a0b58/FTP_Einstellungen_HD_2_en.webp 230w", "/en/static/5c6593e3aaf6cf15699512bf8cf74195/8626f/FTP_Einstellungen_HD_2_en.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5c6593e3aaf6cf15699512bf8cf74195/e83b4/FTP_Einstellungen_HD_2_en.jpg 230w", "/en/static/5c6593e3aaf6cf15699512bf8cf74195/20e5d/FTP_Einstellungen_HD_2_en.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5c6593e3aaf6cf15699512bf8cf74195/20e5d/FTP_Einstellungen_HD_2_en.jpg",
              "alt": "Alarm FTP Upload Setup for the 720p Camera Series",
              "title": "Alarm FTP Upload Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ConfigTable mdxType="ConfigTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Next please submit all settings and test them afterwards to make sure the settings are correct.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f2393ca50a573a034c154f3eaf59c321/29d31/FTP_Einstellungen_HD_3_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABxfYnhZQH/8QAGBABAQEBAQAAAAAAAAAAAAAAAgEDABH/2gAIAQEAAQUC3i8m1Cij5gqXDK9Mye//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIRIRJR/9oACAEBAAY/ArjntFNdItGo2JSR/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBYYGR/9oACAEBAAE/IRXXuc3PuAZl5cF6YQASaRfYdhk//9oADAMBAAIAAwAAABB8P//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EEf/xAAdEAACAgIDAQAAAAAAAAAAAAABEQAhMUFRYcHR/9oACAEBAAE/EBrPGAGWyhSBmtR1TqDBT0FBFD2XDwUK31LUpy32Ioc0CZ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2393ca50a573a034c154f3eaf59c321/e4706/FTP_Einstellungen_HD_3_en.avif 230w", "/en/static/f2393ca50a573a034c154f3eaf59c321/d1af7/FTP_Einstellungen_HD_3_en.avif 460w", "/en/static/f2393ca50a573a034c154f3eaf59c321/70e80/FTP_Einstellungen_HD_3_en.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f2393ca50a573a034c154f3eaf59c321/a0b58/FTP_Einstellungen_HD_3_en.webp 230w", "/en/static/f2393ca50a573a034c154f3eaf59c321/bc10c/FTP_Einstellungen_HD_3_en.webp 460w", "/en/static/f2393ca50a573a034c154f3eaf59c321/426ac/FTP_Einstellungen_HD_3_en.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2393ca50a573a034c154f3eaf59c321/e83b4/FTP_Einstellungen_HD_3_en.jpg 230w", "/en/static/f2393ca50a573a034c154f3eaf59c321/e41a8/FTP_Einstellungen_HD_3_en.jpg 460w", "/en/static/f2393ca50a573a034c154f3eaf59c321/29d31/FTP_Einstellungen_HD_3_en.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f2393ca50a573a034c154f3eaf59c321/29d31/FTP_Einstellungen_HD_3_en.jpg",
              "alt": "Alarm FTP Upload Setup for the 720p Camera Series",
              "title": "Alarm FTP Upload Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`Please open the menu `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/Actions/"
      }}>{`Alarm / Actions`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cdd3608c3bf1e15e802fc5177b9015c8/20e5d/FTP_Einstellungen_HD_4_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB61zcsNhJwVYP/8QAGhAAAgMBAQAAAAAAAAAAAAAAABEBAhIQIf/aAAgBAQABBQJ+szWxmBR1EH//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAYEAACAwAAAAAAAAAAAAAAAAABAhAgIf/aAAgBAQAGPwKNUG//xAAaEAEBAQADAQAAAAAAAAAAAAABABEhMVHh/9oACAEBAAE/IeH1GiUax6ko7kF0EAdWHljwul//2gAMAwEAAgADAAAAEAv3AP/EABYRAQEBAAAAAAAAAAAAAAAAACEAEP/aAAgBAwEBPxAjf//EABURAQEAAAAAAAAAAAAAAAAAACAh/9oACAECAQE/EKP/xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMXFBUYGR8P/aAAgBAQABPxBb5w+03gD91HARhYz6jVO1viBYumGNAtiyqL6gN0XxAGAN8T//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cdd3608c3bf1e15e802fc5177b9015c8/e4706/FTP_Einstellungen_HD_4_en.avif 230w", "/en/static/cdd3608c3bf1e15e802fc5177b9015c8/e46ac/FTP_Einstellungen_HD_4_en.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cdd3608c3bf1e15e802fc5177b9015c8/a0b58/FTP_Einstellungen_HD_4_en.webp 230w", "/en/static/cdd3608c3bf1e15e802fc5177b9015c8/8626f/FTP_Einstellungen_HD_4_en.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cdd3608c3bf1e15e802fc5177b9015c8/e83b4/FTP_Einstellungen_HD_4_en.jpg 230w", "/en/static/cdd3608c3bf1e15e802fc5177b9015c8/20e5d/FTP_Einstellungen_HD_4_en.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cdd3608c3bf1e15e802fc5177b9015c8/20e5d/FTP_Einstellungen_HD_4_en.jpg",
              "alt": "Alarm FTP Upload Setup for the 720p Camera Series",
              "title": "Alarm FTP Upload Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`In the Overlay which opens please select what the camera should do in case of a motion alarm. The camera can upload pictures to an FTP Server when a motion is triggered. In total 4 pictures will be uploaded in which you can see the object that caused the motion. All pictures will be uploaded in the full HD resolution 1280 x 720. You can also upload videos to your FTP server. Therefore, you will need to insert a SD card into your camera. The video will then be recorded to the SD card and uploaded to your FTP server. You find more information about the settings for motion detection `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Setup"
      }}>{`here`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/333b998d4662bb4a60602e04e610c1e7/29d31/FTP_Einstellungen_HD_5_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFDrTiLSP/EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMSERP/2gAIAQEAAQUCvRwFuKkNqc2PCowVoJ//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhIgIy/9oACAEBAAY/Ap5oh6JRqzyUj//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExUXGR/9oACAEBAAE/IdC5rmXSLI90XdEumQ1YUfMX6xLIo//aAAwDAQACAAMAAAAQzP8A/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQMBAT8Qh//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EEf/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFh8P/aAAgBAQABPxBhV2V6NB9gbE23U9ZeTV3aMEwlY0EUrxNxz8dyt/koWf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/333b998d4662bb4a60602e04e610c1e7/e4706/FTP_Einstellungen_HD_5_en.avif 230w", "/en/static/333b998d4662bb4a60602e04e610c1e7/d1af7/FTP_Einstellungen_HD_5_en.avif 460w", "/en/static/333b998d4662bb4a60602e04e610c1e7/70e80/FTP_Einstellungen_HD_5_en.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/333b998d4662bb4a60602e04e610c1e7/a0b58/FTP_Einstellungen_HD_5_en.webp 230w", "/en/static/333b998d4662bb4a60602e04e610c1e7/bc10c/FTP_Einstellungen_HD_5_en.webp 460w", "/en/static/333b998d4662bb4a60602e04e610c1e7/426ac/FTP_Einstellungen_HD_5_en.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/333b998d4662bb4a60602e04e610c1e7/e83b4/FTP_Einstellungen_HD_5_en.jpg 230w", "/en/static/333b998d4662bb4a60602e04e610c1e7/e41a8/FTP_Einstellungen_HD_5_en.jpg 460w", "/en/static/333b998d4662bb4a60602e04e610c1e7/29d31/FTP_Einstellungen_HD_5_en.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/333b998d4662bb4a60602e04e610c1e7/29d31/FTP_Einstellungen_HD_5_en.jpg",
              "alt": "Alarm FTP Upload Setup for the 720p Camera Series",
              "title": "Alarm FTP Upload Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-4",
        "aria-label": "step 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 4`}</h3>
    <p>{`In addition to the upload during an alarm you can also upload pictures in a time interval. Thanks to this function you can create time laps recordings. Please open the menu Alarm / Photo Series.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/22ee90d1a1496010b4c0b9fcb2db4e73/20e5d/FTP_Einstellungen_HD_6_en.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "123.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAB7Ti6kYMPRpkaA//EABoQAAIDAQEAAAAAAAAAAAAAABARAAECIDH/2gAIAQEAAQUCF4zcYYc94//EABYRAQEBAAAAAAAAAAAAAAAAAAAREP/aAAgBAwEBPwFMr//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABgQAQEAAwAAAAAAAAAAAAAAAAEgECEx/9oACAEBAAY/AsbBnjP/xAAfEAACAQIHAAAAAAAAAAAAAAAAASEQYREgMUGBkeH/2gAIAQEAAT8hSmjHF5dGomxDY4fQl8CZJZP/2gAMAwEAAgADAAAAEGQmfv/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EIpVP//EABYRAQEBAAAAAAAAAAAAAAAAAAAREP/aAAgBAgEBPxBcj//EAB8QAQACAgAHAAAAAAAAAAAAAAEAESExEFFhcYGRof/aAAgBAQABPxBROa7ypViCrBagRYDm3BWreYtI/UulG3oJSwHpG2wGs4ho4M//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22ee90d1a1496010b4c0b9fcb2db4e73/e4706/FTP_Einstellungen_HD_6_en.avif 230w", "/en/static/22ee90d1a1496010b4c0b9fcb2db4e73/e46ac/FTP_Einstellungen_HD_6_en.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/22ee90d1a1496010b4c0b9fcb2db4e73/a0b58/FTP_Einstellungen_HD_6_en.webp 230w", "/en/static/22ee90d1a1496010b4c0b9fcb2db4e73/8626f/FTP_Einstellungen_HD_6_en.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22ee90d1a1496010b4c0b9fcb2db4e73/e83b4/FTP_Einstellungen_HD_6_en.jpg 230w", "/en/static/22ee90d1a1496010b4c0b9fcb2db4e73/20e5d/FTP_Einstellungen_HD_6_en.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/22ee90d1a1496010b4c0b9fcb2db4e73/20e5d/FTP_Einstellungen_HD_6_en.jpg",
              "alt": "Alarm FTP Upload Setup for the 720p Camera Series",
              "title": "Alarm FTP Upload Setup for the 720p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-5",
        "aria-label": "step 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 5`}</h3>
    <p>{`Please activate that you want to have a photo series uploaded to an FTP server. Next you can choose if the picture should be uploaded with a new file name each time (timestamp) or if you want to have a fixed file name and the file will be replaced each time. Also you can set the time interval in which the picture should be uploaded. The smallest value is 1 second but it also depends on the upload speed of your internet connection. All pictures will be uploaded in HD resolution 1280 x 720.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      